import React from 'react';

import styled from 'styled-components';

  const StyledBoton = styled.div`
  
  .accion{
      border: none;
      padding: 1rem;
      border-radius: 5px;
      font-size: 20px;
      border: none;
      background: none;
      cursor: pointer;
      color: ${(props)=>props.colorLetra};
      background: ${(props)=>props.colorFondo};
  }
  
  .BotonDesactivado{
    border: none;
      padding: 1rem;
      border-radius: 5px;
      font-size: 20px;
      border: none;
      background: none;
      cursor: not-allowed;
      color: #FFF;
      background: var(--BotonDesactivado);
  }
  `
const BotonesAcciones = (props) => {

  


  return (
    <StyledBoton>
      <div className='contenedorBotonAccion'>
        <StyledBoton colorLetra={props.colorLetra} colorFondo={props.colorFondo}>
          <button className={props.desactivar ? "BotonDesactivado" :'accion'} onClick={props.clickBoton} disabled={props.desactivar} >{props.accion}</button>
        </StyledBoton>
      </div>
    </StyledBoton>
  )
}

export default BotonesAcciones