import axios from 'axios';
import React, { useState } from 'react'
import useSelect from './useSelect';
import { useSelectPKT1 } from './useSelectPKT1';
import { useAlert } from './useAlert';
import { useGraficas } from './useGraficas';
import moment from 'moment';

const useUsuarios = () => {

  const { ConvertirAObjeto, objetoSelect } = useSelect();

  const {ServicioGraficaFiltradoUsuarios} = useGraficas();

  const filtroInicio = moment().subtract(2, "week");
  const [fechaInicio, setFechaInicio] = useState(filtroInicio.format('YYYY-MM-DD'));
  const ayer = moment().subtract(1, 'days');
  const [fechaFin, setFechaFin] = useState(ayer.format('YYYY-MM-DD'));

    const [dataUser, setDataUser] = useState([]);
    const [dataSelect, setDataSelect] = useState([]);
    const [nameUser, setNameUser] = useState("");
    const [idUser, setIdUser] = useState(0);
    const [cargando, setCargando] = useState(false);
    const { ConvertirASelect } = useSelectPKT1();
    const [SoloUsuarios, setSoloUsuarios] = useState();
    const { alertError } = useAlert();
    const [FiltradoGraficaHoras, setFiltradoGraficaHoras] = useState();


    const showUsuarios = async (url) => {
        // let configpost = {
        //   method: 'post',
        //   url: url,
        //   headers: {
        //     'Content-Type': 'application/json'
        //   }
        // };

        
        setCargando(true);

        try{
          const RespuestaServicio = await axios.get(url);

          setCargando(false);
          // console.log(response.data.value);
          let RespuestaAConvertir = RespuestaServicio.data.value;
  
          ConvertirASelect(RespuestaAConvertir, "idUsuario", "descripcion");
  
          const NuevaRespuesta = RespuestaAConvertir.map(resp => {
            const RehacerObjeto = { "id": resp.idUsuario, "descripcion":resp.nombre }
            return RehacerObjeto
          })
  
          let NuevoArray =  [{ "id": 0, "descripcion": "Todos" }, ...NuevaRespuesta];
  
          setSoloUsuarios(NuevaRespuesta);
          // setIdUser(NuevaRespuesta[0].id);
          // setNameUser(NuevaRespuesta[0].descripcion);
          
  
          // console.log(NuevoArray);
         setDataSelect(NuevoArray);
  
          // setNameUser(ObjetoNombreUser);
          // setIdUser(ObjetoIdUser);
          setDataUser(RespuestaServicio.data.value);
          // console.log(response.data)
          // setDataUser(response.data.value);
          // ConvertirAObjeto(response.data.value);  
          // console.log(objetoSelect)
        }catch(err){
          alertError(err);
        }
      }

      const MostrarUnicamenteUsuarios = async(url) =>{
        setCargando(true);

        try{
          const RespuestaServicio = await axios.get(url);

          let RespuestaAConvertir = RespuestaServicio.data.value;
  
          ConvertirASelect(RespuestaAConvertir, "idUsuario", "descripcion");
  
          const NuevaRespuesta = RespuestaAConvertir.map(resp => {
            const RehacerObjeto = { id: resp.idUsuario, descripcion:resp.nombre }
            return RehacerObjeto
          })

          setCargando(false);

          setDataSelect(NuevaRespuesta);

          let idUsuario = NuevaRespuesta[0].id;

          let RespuestaGrafica = await ServicioGraficaFiltradoUsuarios(`${process.env.REACT_APP_API_REPORTES_SOPORTE}Usuarios/HorasRegistradas?FechaInicio=${fechaInicio}&FechaFin=${fechaFin}&IdUsuario=${idUsuario}`);

          return RespuestaGrafica

          // setIdUser(NuevaRespuesta[0].id);
          setNameUser(NuevaRespuesta[0].descripcion);
        }catch(err){
          console.log(err);
          // alertError(err);
        }


      }

  return {
    showUsuarios,
    nameUser,
    setNameUser,
    dataUser,
    idUser,
    setIdUser,
    dataSelect,
    cargando,
    SoloUsuarios,
    MostrarUnicamenteUsuarios
  }
}

export default useUsuarios