import axios from 'axios'
import React, { useState } from 'react'

export const useGraficas = () => {

  const [DatosGrafica, setDatosGrafica] = useState([]);
  const [categoria, setCategoria] = useState([]);

  const [loading, setLoading] = useState(false);

  const ServicioGraficas = async (url) => {

    setLoading(true);

    try {
      let RespuestaServicio = await axios.get(url);

      setLoading(false);

      const DatosServicio = RespuestaServicio.data;

      const nuevaRespuesta = DatosServicio.map((item, index) => ({
        id: index,
        descripcion: item.fechaSeguimiento,
      }))

      setCategoria(nuevaRespuesta);
      setDatosGrafica(RespuestaServicio.data)

    } catch (error) {
      setLoading(false);
      console.log(error);

    }
  }

  const ServicioGraficaFiltradoUsuarios = async (url) => {

    try {
      let RespuestaServicio = await axios.get(url);

      setLoading(false);

      return RespuestaServicio.data

    } catch (error) {
      setLoading(false);
      console.log(error);

    }
  }


  return {
    ServicioGraficas,
    ServicioGraficaFiltradoUsuarios,
    DatosGrafica,
    setDatosGrafica,
    categoria,
    setCategoria,
    loading
  }
}
