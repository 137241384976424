import axios from 'axios';
import React, { useState } from 'react'

const useSelect= () => {

    const ConvertirAObjeto = async (objeto) => {

      const convertidoSelect = objeto.map((item, index) => ({
        id: index,
        descripcion: item.fechaSeguimiento,
      }))
      return convertidoSelect;
    }

  return {
    ConvertirAObjeto
  }
}

export default useSelect